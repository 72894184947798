import * as React from "react";

function SvgIndoor(props) {
  return (
    <svg viewBox="0 0 89.6 80" {...props}>
      <defs>
        <style>{".indoor_svg__cls-1{fill-rule:evenodd}"}</style>
      </defs>
      <g id="indoor_svg__Layer_2" data-name="Layer 2">
        <g id="indoor_svg__Layer_1-2" data-name="Layer 1">
          <path
            className="indoor_svg__cls-1"
            d="M44.8 0L0 14.12v6.39L44.8 6.4l44.8 14.11v-6.39L44.8 0zM86.56 64.1a5.94 5.94 0 01-2.91-.1c-1.26-.29-2.41-1.64-4.5-1.56s-5.17 2-8.09 2-6.29-2.1-9.32-2.1-5.74 2.15-8.64 2.22-5.88-2-8.76-2-5.69 1.76-8.42 1.74-5.08-2-7.75-1.92-5.74 2.25-8.42 2.38-5.15-2-7.53-2-5.11 1.49-6.73 1.83-2.65.34-3 .1v5.68c.39.24 1.42.21 3-.1s4.32-1.88 6.73-1.83 4.83 2 7.53 2 5.68-2.3 8.42-2.38 5.06 1.89 7.75 1.93 5.5-1.8 8.42-1.75 5.82 2 8.76 2 5.63-2.18 8.64-2.2 6.36 2.06 9.32 2.11 6-1.94 8.09-2 3.24 1.27 4.5 1.55a5.94 5.94 0 002.91.09z"
          />
          <path
            className="indoor_svg__cls-1"
            d="M38.46 61.08l23.22-12.53c-1.82-4-3.11-7.1-3.86-9.28s-1.1-2.64-.66-3.67-.16-1.22 3.23-2.6S74 27.34 77.27 27.13s3.12 3.45 2.58 4.67-3.61 1.49-5.9 2.44-4.89 1.9-7.84 3l10.62 23.65c-1.37 1.19-3.18 1.65-5.64 1.47s-5.82-2.64-8.93-2.66-6.63 2.69-9.46 2.75-5.09-2.33-7.47-2.56a12.43 12.43 0 00-6.77 1.19z"
          />
          <path
            className="indoor_svg__cls-1"
            d="M80.18 40.62a6.86 6.86 0 11-6.48 6.85 6.68 6.68 0 016.48-6.85M22.79 61.54a58.53 58.53 0 01-8.59-2.38C11.13 58 5.9 56.5 4.67 55a3 3 0 012.16-4.73c2.5-.14 7.48 2.11 12.64 4s11 4.15 18 7a5.38 5.38 0 01-3.42.41c-1.46-.25-3.31-1.61-5.19-1.65a13.55 13.55 0 00-6 1.5zM86.56 73.6a6.2 6.2 0 01-2.91-.09c-1.26-.28-2.41-1.65-4.5-1.56S74 74 71.06 74s-6.29-2.1-9.32-2.1S56 74 53.1 74.06s-5.88-2-8.76-2-5.69 1.76-8.42 1.74-5.08-2-7.75-1.92-5.74 2.25-8.42 2.38-5.15-2-7.53-2-5.11 1.49-6.73 1.83-2.65.33-3 .09v5.68a5.36 5.36 0 003-.09c1.62-.34 4.32-1.87 6.73-1.83s4.83 2.06 7.53 2 5.68-2.28 8.42-2.38 5.06 1.86 7.75 1.91 5.5-1.81 8.42-1.73 5.82 2 8.76 2 5.63-2.17 8.64-2.19 6.36 2 9.32 2.1 6-1.94 8.09-2 3.24 1.28 4.5 1.57a5.92 5.92 0 002.91.08z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIndoor;


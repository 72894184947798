import * as React from "react";

function SvgQuote(props) {
  return (
    <svg viewBox="0 0 40 34.28" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Layer 2">
            <path
              d="M37.14 5.71a6.07 6.07 0 00-5.71 6.35A6.33 6.33 0 0035 17.77a8.57 8.57 0 11-12.11 7.94V20c0-11 6.4-20 14.28-20a2.86 2.86 0 110 5.71zm-20 20a8.57 8.57 0 01-17.14 0V20C0 9 6.4 0 14.29 0a2.86 2.86 0 010 5.71 6.07 6.07 0 00-5.72 6.35 6.23 6.23 0 003.54 5.71 8.52 8.52 0 015.03 7.94z"
              fillRule="evenodd"
              data-name="Layer 1-2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgQuote;


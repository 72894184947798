import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import Loadable from 'react-loadable';
import { Container, Block, Loading } from '../primitives';
import TrainingImage from './TrainingImage';

const Section = styled.section.attrs({ className: 'relative bg-white' })`
  h2 {
    font-size: 2.5rem;
    color: #241c15;
    margin: 0 0 1rem 0;
  }
  p {
    font-size: 1.125rem;
    font-weight: 300;
    margin: 0 0 2rem 0;
    line-height: 1.4;
    @media (min-width: 600px) {
      font-size: 1.25rem;
      margin: 0 0 3rem 0;
    }
  }
  strong {
    font-weight: 600;
  }
`;

const Grid = styled.div`
  display: grid;
  @media (min-width: 800px) {
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 3rem;
  }
`;

const ImageFrame = styled.div.attrs({ className: 'shadow-lg' })`
  height: 100%;
`;

const BodyWrapper = styled.div`
  max-width: 600px;
  margin: 2rem auto 0;
`;

const Training = () => {
  //
  return (
    <Section>
      <Container>
        <Block>
          <Grid>
            <div>
              <ImageFrame>
                <TrainingImage />
              </ImageFrame>
            </div>
            <div>
              <BodyWrapper>
                <h2>Consistent Training</h2>
                <p>
                  <strong>Regular </strong>training sessions are the most important aspect of becoming a great swimmer.
                  Consistency is needed for measurable progress.
                </p>
                <p>
                  AT SDAA, we impress upon our swimmers the importance of attending training <strong>regularly</strong>{' '}
                  and adhere to the systematically designed schedules to derive the full benefits of their enrolled
                  program.
                </p>
              </BodyWrapper>
            </div>
          </Grid>
        </Block>
      </Container>
    </Section>
  );
};

export default Training;

Training.propTypes = {
  //
};

Training.defaultProps = {
  //
};

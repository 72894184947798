import * as React from "react";

function SvgAdults(props) {
  return (
    <svg viewBox="0 0 66.34 40" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M13.66 0A13.68 13.68 0 000 13.66v6.22a14 14 0 001.49 6.21v1.74a12.18 12.18 0 0024.35 0v-1.74a13.3 13.3 0 001.49-6.21v-6.22A13.68 13.68 0 0013.66 0zm10 27.83a10 10 0 01-20 0V15.65a10.06 10.06 0 016.2-9.27V8.2c0 2.57 1.24 5.63 5.63 5.63 2.73 0 6 .25 8.2 2.24z" />
          <path d="M13.66 28a2.38 2.38 0 01-1.73-.74 11.57 11.57 0 01-2.16.16c-2.9 0-4.39 1.49-4.39 4.39h2.16c0-1.74.49-2.23 2.23-2.23a8 8 0 003.89-.67 8 8 0 003.9.67c1.74 0 2.23.49 2.23 2.23H22c0-2.9-1.49-4.39-4.39-4.39a11.57 11.57 0 01-2.16-.16 2.38 2.38 0 01-1.79.74zM62.61 13.83v-.17a13.67 13.67 0 00-27.33 0v.67c-.58 8.36-3.73 12.09-3.73 15.73 0 2.9 3.73 5.39 9.2 6.63a12 12 0 0016.39 0c5.47-1.24 9.2-3.73 9.2-6.63 0-4.14-3.15-9.6-3.73-16.23zm-3.61 14a10 10 0 01-20 0V15.32c3.48-3.23 9.86-4 10-6.54.17 2.57 6.55 3.31 10 6.54v12.51z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgAdults;


import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import axios from 'axios';
import { newsletterValidation } from '../../utils';
import { Button, Anchor, Dialog } from '../primitives';
import { Input } from '../primitives/Input';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const Wrapper = styled.div.attrs({})``;

const Message = styled.div`
  max-width: 400px;
  margin: 0 auto;
  h3 {
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0 0 1rem 0;
  }
  p {
    margin: 0 0 2rem 0;
  }
`;

const ContactForm = () => {
  //
  const [isSuccessOpen, toggleSuccessDialog] = useState(false);
  const [isFailOpen, toggleFailDialog] = useState(false);

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(QUERY);

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        validationSchema={newsletterValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            const res = await axios({
              method: 'post',
              url: `https://api.hsforms.com/submissions/v3/integration/submit/7320947/be500821-b9f1-4dae-896a-d8d2b8767950`,
              headers: { 'Content-Type': 'application/json' },
              data: {
                fields: [
                  {
                    name: 'email',
                    value: values.email,
                  },
                  {
                    name: 'firstname',
                    value: values.firstName,
                  },
                  {
                    name: 'lastname',
                    value: values.lastName,
                  },
                ],
              },
            });
            if (res.status === 200) {
              actions.resetForm();
              toggleSuccessDialog(true);
            }
          } catch (error) {
            toggleFailDialog(true);
          }
        }}
      >
        {({ values, handleChange, errors, setErrors, isSubmitting }) => {
          //
          const resetError = field => {
            setErrors({ ...errors, [field]: '' });
          };

          return (
            <Wrapper>
              <Form>
                <div id="form-wrapper">
                  <div id="grid-firstname">
                    <Input
                      labelText="First name"
                      inputName="firstName"
                      value={values.firstName}
                      errorText={errors.firstName}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-lastname">
                    <Input
                      labelText="Last Name"
                      inputName="lastName"
                      value={values.lastName}
                      errorText={errors.lastName}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-email">
                    <Input
                      asterisk
                      asteriskColor="tomato"
                      labelText="Email"
                      inputName="email"
                      value={values.email}
                      errorText={errors.email}
                      errorBorderColor="tomato"
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-privacy">
                    <Anchor href={`${siteUrl}/privacy`} target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </Anchor>
                  </div>

                  <div id="grid-button">
                    <Button type="submit" label="Send message" disabled={isSubmitting}>
                      Sign me up!
                    </Button>
                  </div>
                </div>
              </Form>
            </Wrapper>
          );
        }}
      </Formik>
      <Dialog open={isSuccessOpen} close={toggleSuccessDialog}>
        <Message>
          <h3>Thank you for signing up.</h3>
          <p>Expect some cool content coming to your inbox soon.</p>
        </Message>
      </Dialog>
      <Dialog open={isFailOpen} close={toggleFailDialog}>
        <Message>
          <h3>Something has gone wrong.</h3>
          <p>Please try again.</p>
        </Message>
      </Dialog>
    </>
  );
};

export default ContactForm;

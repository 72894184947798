import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion, AnimatePresence } from 'framer-motion';
import Loadable from 'react-loadable';
import { Container, Block, Loading, Button } from '../primitives';

const lightbox = {
  initial: { opacity: 0, transform: 'scale(0.8)' },
  animate: { opacity: 1, transform: 'scale(1)' },
  exit: { opacity: 0, transform: 'scale(0.8)' },
};

const QUERY = graphql`
  query {
    allImages: allFile(filter: { relativeDirectory: { eq: "facility" } }, sort: { fields: name }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

const Section = styled.section`
  background: #ffd700;
  padding: 0 0 5rem;
`;

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const LightBox = styled(motion.div)`
  position: fixed;
  z-index: 1050;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 4rem 2rem;
`;

const LightBoxImg = styled(Img)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const LightBoxButtonWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 1051;
`;

const ImageWrapper = styled.div`
  position: relative;
  transition: 300ms;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  filter: sepia(1);
  :hover {
    transform: scale(1.5);
    z-index: 1000;
    filter: none;
  }
`;

const MainGrid = styled.div`
  display: grid;
  max-width: 320px;
  margin: 0 auto;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  @media (min-width: 672px) {
    max-width: 672px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  @media (min-width: 1008px) {
    max-width: 1008px;
    display: block;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  max-width: 320px;
  margin: 0 auto;
  @media (min-width: 1008px) {
    max-width: 1008px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0 auto;
  }
`;

const TextWrapper = styled.div`
  max-width: 320px;
  display: none;
  @media (min-width: 672px) {
    display: block;
  }
  @media (min-width: 1008px) {
    display: none;
  }

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    margin: 0 0 1rem 0;
  }
`;

const Heading = styled.div`
  border-bottom: 1px #faf089 solid;
  max-width: 1008px;
  margin: 0 auto 2rem;
  h2 {
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    margin: 0 0 0.5rem;
  }
`;

const Facility = () => {
  //
  const {
    allImages: { edges: images },
  } = useStaticQuery(QUERY);

  const [isLightBoxOpen, toggleLightBox] = useState(false);
  const [currentImage, setCurrentImage] = useState(images[0].node.childImageSharp.fluid);

  return (
    <>
      <Section>
        <Heading>
          <h2>Gallery</h2>
        </Heading>
        <MainGrid>
          <div>
            <Grid>
              {images.map(image => (
                <ImageWrapper
                  key={image.node.id}
                  onClick={() => {
                    setCurrentImage(image.node.childImageSharp.fluid);
                    toggleLightBox(true);
                  }}
                >
                  <ImageSharp fluid={image.node.childImageSharp.fluid} />
                </ImageWrapper>
              ))}
            </Grid>
          </div>
          <TextWrapper>
            <h3>SDAA is a tier-one facility</h3>
            <p>We have an excellent swimmer development program and a highly successful competitive team.</p>
          </TextWrapper>
        </MainGrid>
      </Section>
      <AnimatePresence>
        {isLightBoxOpen && (
          <LightBox initial="initial" animate="animate" exit="exit" variants={lightbox}>
            <LightBoxButtonWrapper className="flex justify-end">
              <Button
                type="button"
                onClick={() => {
                  toggleLightBox(false);
                }}
              >
                Close
              </Button>
            </LightBoxButtonWrapper>
            <LightBoxImg fluid={currentImage} />
          </LightBox>
        )}
      </AnimatePresence>
    </>
  );
};

export default Facility;

Facility.propTypes = {
  //
};

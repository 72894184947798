import * as React from "react";

function SvgHeated(props) {
  return (
    <svg viewBox="0 0 77.58 80" {...props}>
      <g data-name="Layer 2">
        <path
          d="M44.59 59.56c-.13.87-.28 2.5-.65 4.1-1 4.55-3.6 8.56-7.3 12.21-1.37 1.34-1.11 2.75.46 3.63a3.79 3.79 0 004.6-.69c7.91-7.81 10.85-16.5 8.44-26.21-1.45-5.86-5.38-10.74-10.06-15.43-6.43-6.46-8.37-13.8-6.22-21.74a25 25 0 017-11.2c1.43-1.4 1.25-2.78-.23-3.68a4 4 0 00-4.77.63c-6.42 6.23-9.6 13.3-9.2 21.23.37 7.24 3.76 13.64 9.76 19.26a23.94 23.94 0 018.17 17.89zM63.16 79a4.08 4.08 0 004.82.07 3.47 3.47 0 00.29-.25q11.51-11.07 8.79-25c-1.24-6.25-5.24-11.49-10.21-16.48-7.13-7.19-8.85-15.25-5.68-23.88a26 26 0 016.4-9.32c1.36-1.34 1.09-2.84-.4-3.66a3.86 3.86 0 00-4.63.68Q50.66 12.7 54 27.12c1.31 5.62 4.89 10.42 9.41 15 9.07 9.09 10.25 18.92 3.69 29.2a50.4 50.4 0 01-4.26 5 1.84 1.84 0 00.1 2.51 2 2 0 00.22.17zm-50.34 1a7.68 7.68 0 002.07-1.06c8.35-8.06 11.18-17.12 8.39-27.17-1.55-5.61-5.51-10.25-10-14.76-6-6.11-8-13.09-6.31-20.63a24.53 24.53 0 017.2-12.11c1.51-1.47 1.33-2.86-.23-3.76a3.91 3.91 0 00-4.75.71C3.48 6.74.33 13 0 20c-.35 8.07 3.08 15.14 9.6 21.5 9.55 9.3 11 19.35 4.1 30-1.09 1.69-2.75 3.15-4 4.76s.07 3.68 3.12 3.74z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgHeated;


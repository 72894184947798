import * as React from "react";

function SvgCoach(props) {
  return (
    <svg viewBox="0 0 81.31 80" {...props}>
      <g data-name="Layer 2">
        <path
          d="M52.33 50.12a18.13 18.13 0 012.6 3.8 1 1 0 01-.6 1.4c-.2 0-.2.2-.4.2a1.1 1.1 0 01-1-.6 12.67 12.67 0 00-3.6-4.8c-1.4-1.2-3.8-2.8-9.39-3.2s-11.4 2.8-14.4 4.8a.92.92 0 01-1.4-1c.8-3 2-8.39 3.4-13.39 2-7.6 6-17.79 10-18.19 5.39-.4 8.79-1.8 12.39-1.8 4.4 0 6.8-1.6 6.8-3.6s-1.8-1.6-3-2.4c-.6-.4 2.6-3 3.8-5.39 1-2.4-6.8-5.2-11.6-5.8C41.14-.65 39.14 2 37.74 4a181.85 181.85 0 01-19 21.39c-8.2 8-15 22.78-18 29.78a10 10 0 00.8 9.2c9 13.19 38 14.59 48.17 12.39 8.2 4.8 31.58 7 31.58-12.59-.18-18.64-18.37-20.84-28.96-14.05z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgCoach;


import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const QUERY = graphql`
  query {
    image: file(sourceInstanceName: { eq: "images"}, name: { eq: "training" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`;

const TrainingImage = () => {
  //
  const { image } = useStaticQuery(QUERY);

  return <ImageSharp fluid={image.childImageSharp.fluid } />
};

export default TrainingImage;
import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { useIntersection } from 'react-use';

const container = {
  enter: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
  exit: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const item = {
  initial: { x: 8, opacity: 0 },
  enter: {
    x: 0,
    opacity: 1,
  },
};

const Section = styled.section`
  background: #ffd700;
  h2 {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0 0 1.5rem 0;
  }
`;

const YouTubeWrapper = styled.div.attrs({ className: 'shadow-lg' })`
  margin: 0 auto;
  width: 320px;
  height: 180px;
  @media (min-width: 1008px) {
    width: 480px;
    height: 270px;
  }
`;

const Grid = styled(motion.div)`
  max-width: 320px;
  padding: 4rem 0;
  display: grid;
  grid-row-gap: 2rem;
  margin: 0 auto;
  @media (min-width: 672px) {
    max-width: 672px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  @media (min-width: 1008px) {
    max-width: 1008px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
`;

const YouTube = () => {
  //
  const youtube = useRef(null);

  const youtubeIx = useIntersection(youtube, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  return (
    <Section>
      <Grid
        variants={container}
        initial="initial"
        animate={youtubeIx && youtubeIx.isIntersecting ? 'enter' : 'initial'}
        exit="exit"
        ref={youtube}
      >
        <div>
          <motion.div key="yuotube1" variants={item}>
            <h2>Training Session</h2>
            <YouTubeWrapper>
              <ReactPlayer url="https://youtu.be/PQDBioxH9kg" width="100%" height="100%" controls />
            </YouTubeWrapper>
          </motion.div>
        </div>
        <div>
          <motion.div key="yuotube2" variants={item}>
            <h2>Indoor Pool Facility</h2>
            <YouTubeWrapper>
              <ReactPlayer url="https://youtu.be/lauVuRt6dA8" width="100%" height="100%" controls />
            </YouTubeWrapper>
          </motion.div>
        </div>
      </Grid>
    </Section>
  );
};

export default YouTube;

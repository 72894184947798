import * as React from 'react';

function SvgBubbleQuote(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle cx={50} cy={50} r={50} fill="#FFD700" />
          <g data-name="Layer 2">
            <g data-name="Layer 1-2">
              <g data-name="Layer 2-2">
                <path
                  d="M26.77 65.58a8.29 8.29 0 007.79-8.66 8.64 8.64 0 00-4.87-7.78A11.68 11.68 0 1146.2 38.31v7.79c0 15-8.73 27.26-19.47 27.26a3.9 3.9 0 11-.46-7.78 3.53 3.53 0 01.46 0zM54 38.31a11.69 11.69 0 0123.37 0v7.79c0 15-8.73 27.26-19.48 27.26a3.9 3.9 0 010-7.78 8.29 8.29 0 007.8-8.66 8.51 8.51 0 00-4.83-7.78A11.63 11.63 0 0154 38.31z"
                  fillRule="evenodd"
                  data-name="Layer 1-2-2"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgBubbleQuote;

import * as React from "react";

function SvgDab(props) {
  return (
    <svg viewBox="0 0 29.94 40" {...props}>
      <g data-name="Layer 2">
        <path
          d="M12 2.58A4.41 4.41 0 008.06 0a4.34 4.34 0 00-4.34 4.35 4.45 4.45 0 00.58 2.17zm17.85 2.09a1.77 1.77 0 00-2.17-1.18L9.38 9.11c-.09 0 7.79-4.31 7.79-4.31a1.78 1.78 0 00.77-2.35 1.79 1.79 0 00-2.36-.77L1 9.2a1.78 1.78 0 00-.9 2 1.77 1.77 0 001.62 1.3h.09l2.54-.13L6.16 26 .23 37.33A1.72 1.72 0 001 39.68a1.82 1.82 0 00.82.23A1.73 1.73 0 003.31 39l7.34-11.37 4.25 11.55a1.8 1.8 0 001.45.82 1.94 1.94 0 001-.27 1.92 1.92 0 00.49-2.4l-4.79-25.69 15.67-4.8a1.7 1.7 0 001.13-2.17z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgDab;


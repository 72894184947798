import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Block } from '../primitives';
import Image from './BreakImage';

const QUERY = graphql`
  query {
    breaks: allSanityBreaks(sort: { fields: start }) {
      edges {
        node {
          start
          title
          end
          id
        }
      }
    }
  }
`;

const Section = styled.section`
  position: relative;
  background: #f7fafc;
`;

const Table = styled.table`
  font-size: 0.875rem;
  width: 100%;
  max-width: 400px;
  td:nth-child(1) {
    color: #2b6cb0;
  }
  td:nth-child(2),
  td:nth-child(3) {
    text-align: right;
    width: 100px;
  }
  tr:nth-child(1) {
    font-weight: 900;
  }
  td {
    padding: 1rem 0 0.5rem 0;
    border-bottom: 1px #c5dbf2 solid;
  }
`;

const TableWrapper = styled.div`
  padding: 2rem 1rem;
  max-width: 480px;
  margin: 0 auto;
  @media (min-width: 480px) {
    padding: 2rem 3rem;
  }
  h2 {
    margin: 2rem 0 0 0;
    font-size: 1.75rem;
  }
  p {
    margin: 0 0 2rem 0;
    font-weight: 300;
  }
`;

const Grid = styled.div`
  display: grid;
  position: relative;
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const InfoBox = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div.attrs({ className: 'shadow-lg' })`
  height: 100%;
`;

const Calendar = () => {
  //
  const {
    breaks: { edges: terms },
  } = useStaticQuery(QUERY);

  const formateDate = date => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const parts = date.split('-', 3);
    return `${parts[2]}-${monthNames[Number(parts[1]) - 1]}`;
  };

  const currentYear = new Date().getFullYear();

  // const year = terms.filter(term => term.node.start.split('-')[0] == currentYear);
  // console.log(year);

  return (
    <Section>
      <Container>
        <Block>
          <Grid>
            <InfoBox>
              <ImageWrapper>
                <Image />
              </ImageWrapper>
            </InfoBox>
            <TableWrapper>
              <h2>2020 Term Breaks</h2>
              <p>SDAA will be on break during these period</p>
              <Table>
                <tbody>
                  <tr>
                    <td>Term</td>
                    <td>Starts</td>
                    <td>Ends</td>
                  </tr>
                  {terms.map(term => {
                    return (
                      <tr key={term.node.id}>
                        <td>{term.node.title} </td>
                        <td>{formateDate(term.node.start)} </td>
                        <td>{formateDate(term.node.end)} </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableWrapper>
          </Grid>
        </Block>
      </Container>
    </Section>
  );
};

export default Calendar;

Calendar.propTypes = {
  //
};

Calendar.defaultProps = {
  //
};

import React, { useState, useContext, useEffect } from 'react';
import { StaticQuery, useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import { Container, Block, Loading } from '../primitives';

const Section = styled.section.attrs({ className: 'relative bg-gray-100' })`
  border-top: 2px #e2e8f0 solid;
  border-bottom: 2px #e2e8f0 solid;
`;

const Events = () => {
  //
  return (
    <Section>
      <Container>
        <Block>
          <h2>Upcoming Events</h2>
        </Block>
      </Container>
    </Section>
  );
};

export default Events;

Events.propTypes = {
  //
};

Events.defaultProps = {
  //
};

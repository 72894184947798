import React from 'react';
import styled from 'styled-components';
import Loadable from 'react-loadable';
import Loading from './HeroImage';
import { useWindowSize } from '../../utils';

const Video = Loadable({
  loader: () => import('./HeroVideo'),
  loading: Loading,
});

const CTA = Loadable({
  loader: () => import('./CTA'),
  loading: Loading,
});

const Section = styled.section.attrs({ className: 'relative bg-white' })``;

const Frame = styled.div.attrs({ className: 'relative overflow-hidden' })`
  height: 736px;
`;

const ImageFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Hero = () => {
  //

  const { width } = useWindowSize();

  return (
    <Section>
      <Frame>
        {width >= 768 && <Video />}
        {width < 768 && (
          <ImageFrame>
            <Loading />
          </ImageFrame>
        )}
        <CTA />
      </Frame>
    </Section>
  );
};

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { SEO } from '../components/primitives';
import Hero from '../components/_homepage/Hero';
import Programs from '../components/_homepage/Programs';
import Events from '../components/_homepage/Events';
import Calendar from '../components/_homepage/Calendar';
import Newsletter from '../components/_homepage/Newsletter';
// import Posts from '../components/_homepage/Posts';
import Features from '../components/_homepage/Features';
import Testimonial from '../components/_homepage/FeaturedTestimonial';
import Training from '../components/_homepage/Training';
import YouTube from '../components/_homepage/YouTube';
import Facility from '../components/_homepage/Facility';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Home = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Swim at your potential" description="SDAA offers swim programs for all level and goals." />
      <Hero />
      <Features />
      <Programs />
      <Testimonial />
      <YouTube />
      <Facility />
      <Training />
      {/* <Events /> */}
      {/* <Calendar /> */}
      {/* <Posts /> */}
      {/* <Newsletter /> */}
    </motion.article>
  );
};

export default Home;

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
import * as React from "react";

function SvgToddler(props) {
  return (
    <svg viewBox="0 0 40 49.05" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M26.76 6.7A6.71 6.71 0 1120.05 0a6.7 6.7 0 016.71 6.7M12.7 14.82h14.77v21.42H12.7zM8.56 14.82l-4.47 4.14-3.22-3.54 4.42-4.14 3.27 3.54z" />
          <path d="M8.56 14.82l2.83 2.95-2.83 3.16-4.47-1.97.49-3.92 3.98-.22zM4.09 19l4.47 2a6.92 6.92 0 01-4.47-2" />
          <path d="M8.56 14.82H21.2v6.11H8.56v-6.11zM6.1 13.35a3.05 3.05 0 11-3-3 3 3 0 013.05 3" />
          <path d="M18.8 14.82v6.11h12.64v-6.11H18.8z" />
          <path d="M31.44 14.82l-2.45 2.78 2.45 3.33 4.47-1.97-.32-3.7-4.15-.44z" />
          <path d="M31.44 14.82l4.47 4.14 3.22-3.54-4.42-4.14-3.27 3.54zM35.91 19l-4.47 2a6.92 6.92 0 004.47-2" />
          <path d="M33.9 13.35a3.05 3.05 0 103.05-3 3 3 0 00-3.05 3M18.75 46a3.06 3.06 0 11-3.06-3.05A3.13 3.13 0 0118.75 46" />
          <path d="M12.7 29.81h6.05V46H12.7zM27.19 46.43l.11.11.06.05zM27.19 46.43l.22.16 4-4.57-.22-.22a3 3 0 00-4.14.43 3.06 3.06 0 00.16 4.2" />
          <path d="M25 44.47l2.23 1.91a3.05 3.05 0 01-.16-4.2 3.11 3.11 0 014.14-.44l-2.12-1.85-.17.06zM25 44.47l-1.58-2.24A7.83 7.83 0 0025 44.47" />
          <path d="M23.38 42.23l1.58 2.24 3.49-2.07.54-2.45-.49-.82-2.83 1.09-2.29 2.01zM31.34 42l-4 4.57a3 3 0 004.19-.38 3 3 0 00-.32-4.3c-.06-.06-.11-.11-.17-.11z" />
          <path d="M17.27 27.19l6.11 15.04L28.83 40l.11-.11.05.06-6.1-14.99-5.62 2.23z" />
          <path d="M28.83 40l.16-.05-.05-.06-.11.11z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgToddler;


/* eslint-disable react/no-danger */

import React, { useState, useContext, useEffect } from 'react';
import { StaticQuery, useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import Loadable from 'react-loadable';
import ReactMarkdown from 'react-markdown';
import { Container, Block, Loading } from '../primitives';
import Quote from '../svg/BubbleQuote';

const QUERY = graphql`
  query {
    allTestimonials: allSanityTestimonials {
      edges {
        ...Testimonials
      }
    }
  }
`;

const Section = styled.section.attrs({ className: 'relative bg-white' })``;

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const MainImageFrame = styled.div.attrs({ className: 'shadow-lg' })`
  position: relative;
  width: 320px;
  height: 240px;
  margin: 0 0 2rem 0;
`;

const QuoteWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: -1rem;
  right: -0.5rem;
  height: 48px;
  width: 48px;
`;

const ThumbnailContainer = styled.div`
  display: flex;
`;

const Thumbnail = styled.div`
  width: 68px;
  height: 68px;
  overflow: hidden;
  border: 1px #e2e8f0 solid;
  cursor: pointer;
  margin: 0 0.25rem;
`;

const ImageContainer = styled.div`
  max-width: 320px;
  margin: 0 auto;
`;

const BodyContainer = styled.div`
  max-width: 320px;
  margin: 0 auto;
  min-height: 380px;
  h3 {
    font-weight: 600;
    font-size: 1.125rem;
  }
  span {
    font-size: 0.875rem;
    font-weight: 300;
  }
  p {
    margin: 0 0 1.5rem 0;
  }
`;

const Grid = styled.div`
  margin: 0 auto;
  max-width: 320px;
  display: grid;
  grid-row-gap: 2.5rem;
  @media (min-width: 680px) {
    max-width: 680px;
    grid-column-gap: 2rem;
    grid-template-columns: 320px 1fr;
  }
`;

const FeaturedTestimonial = () => {
  //

  const {
    allTestimonials: { edges: testimonials },
  } = useStaticQuery(QUERY);

  const [currentImage, setCUrrentImage] = useState(testimonials[1].node.profileImage.asset.fluid);
  const [currentDescription, setCurrentDescription] = useState(testimonials[1].node.testimony);
  const [currentName, setCurrentName] = useState(testimonials[1].node.name);
  const [currentTitle, setCurrentTitle] = useState(testimonials[1].node.title);

  const handleSelect = data => {
    setCUrrentImage(data.profileImage.asset.fluid);
    setCurrentDescription(data.testimony);
    setCurrentName(data.name);
    setCurrentTitle(data.title);
  };

  return (
    <Section>
      <Container>
        <Block>
          <Grid>
            <ImageContainer>
              <MainImageFrame>
                <QuoteWrapper>
                  <Quote />
                </QuoteWrapper>
                <ImageSharp fluid={currentImage} />
              </MainImageFrame>
              <ThumbnailContainer>
                {testimonials.map(testimony => (
                  <Thumbnail
                    key={testimony.node.id}
                    onClick={() => {
                      handleSelect(testimony.node);
                    }}
                  >
                    <ImageSharp fluid={testimony.node.profileImage.asset.fluid} />
                  </Thumbnail>
                ))}
              </ThumbnailContainer>
            </ImageContainer>
            <BodyContainer>
              <ReactMarkdown source={currentDescription} />
              <h3>{currentName}</h3>
              <div>
                <span>{currentTitle}</span>
              </div>
            </BodyContainer>
          </Grid>
        </Block>
      </Container>
    </Section>
  );
};

export default FeaturedTestimonial;

FeaturedTestimonial.propTypes = {
  //
};

FeaturedTestimonial.defaultProps = {
  //
};

import React from 'react';
import styled from 'styled-components';
import Coach from '../svg/Coach';
import Heated from '../svg/Heated';
import Indoor from '../svg/Indoor';
import { Container } from '../primitives';

const Section = styled.section`
  z-index: 499;
  color: #000000;
  font-weight: 300;
  padding: 2rem 1rem;
  background: #ffffff;

  svg {
    height: 20px;
    fill: #3182ce;
    margin: 0 1rem 0 0;
  }
  li {
    display: flex;
    margin: 1rem 0 1rem 0;
  }
  @media (min-width: 600px) {
    ul,
    li {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    li {
      margin: 0 2rem 0 0;
    }
  }
`;

const Features = () => {
  //
  return (
    <Section>
      <Container>
        <ul>
          <li>
            <Indoor />
            Indoor training facility
          </li>
          <li>
            <Heated />
            Heated pool for young swimmers
          </li>
          <li>
            <Coach />
            Dedicated coaches
          </li>
        </ul>
      </Container>
    </Section>
  );
};

export default Features;
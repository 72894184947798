import React, { useRef } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { useIntersection } from 'react-use';
import { motion } from 'framer-motion';
import { Container, Block, Loading, HeroButton as Button } from '../primitives';
import Quote from '../svg/Quote';
import Toddler from '../svg/Toddler';
import Adults from '../svg/Adults';
import Dab from '../svg/Dab';

const QUERY = graphql`
  query {
    toddler: file(sourceInstanceName: { eq: "images" }, name: { eq: "toddler" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    child: file(sourceInstanceName: { eq: "images" }, name: { eq: "child" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    adult: file(sourceInstanceName: { eq: "images" }, name: { eq: "adult" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const container = {
  enter: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
  exit: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const item = {
  initial: { x: 8, opacity: 0 },
  enter: {
    x: 0,
    opacity: 1,
  },
};

const Image1 = Loadable({
  loader: () => import('./ProgramImage'),
  loading: Loading,
});

const Section = styled.section.attrs({ className: 'relative' })`
  z-index: 500;
  background: #c5dbf2;
  svg {
    fill: #3182ce;
  }
`;

const Header = styled.div.attrs({ className: 'relative' })`
  #header-feature-image {
    position: relative;
    z-index: 1;
    grid-area: image;
    min-height: 300px;
  }
  #header-copy {
    position: relative;
    z-index: 1;
    grid-area: copy;
    max-width: 460px;
    margin: 0 auto;
  }
  #header-copy-quote {
    display: none;
    font-family: 'georgia';
    font-size: 2rem;
    padding: 0 0 6rem 0;
  }
  em {
    font-weight: 500;
    font-style: normal;
  }
  h2 {
    font-size: 2.5rem;
    color: #241c15;
    margin: 0 0 1rem 0;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin: 0 0 2rem 0;
    line-height: 1.4;
    @media (min-width: 600px) {
      font-size: 1.375rem;
      margin: 0 0 3rem 0;
    }
  }
  svg {
    height: 24px;
    transform: translate(-2.3rem, 1.2rem);
  }
  display: grid;
  grid-template-areas:
    'copy'
    'image';
  margin: 0 0 4rem 0;

  @media (min-width: 800px) {
    margin: 0 0 6rem 0;
    padding: 0 2rem;
    #header-feature-image {
    }
    #header-copy {
      h2 {
        font-size: 3rem;
      }
      display: grid;
    }
    #header-copy-heading {
      align-self: end;
    }
    #header-copy-quote {
      display: block;
    }
    #header-copy-subtitle {
      font-size: 1.125rem;
      margin: 0;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-template-areas: 'copy image';
  }
`;

const Grid = styled(motion.div)`
  display: grid;
  max-width: 300px;
  margin: 0 auto;
  grid-row-gap: 4rem;
  @media (min-width: 680px) {
    max-width: 640px;
  }
  @media (min-width: 1032px) {
    max-width: 1032px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  justify-items: center;
  margin: 0 auto;
  h2 {
    font-size: 1.75rem;
    margin: 0 0 1.5rem 0;
    display: flex;
    align-items: center;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 300;
  }
  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
  @media (min-width: 1032px) {
    grid-template-columns: 1fr;
    max-width: 100%;
    align-content: start;
  }
`;

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  display: none;
  height: 200px;
  width: 100%;
  border: 0.5rem #ffffff solid;
  outline: 1px #a0aec0 solid;
  overflow: hidden;
  margin: 0 0 1.5rem 0;
  align-self: center;
  @media (min-width: 680px) {
    display: block;
  }
`;

const BodyContainer = styled.div`
  svg {
    height: 24px;
    margin: 0 0 0 1rem;
  }
`;

const FeatureImageWrapper = styled.div.attrs({ className: 'shadow-lg' })`
  height: 400px;
  max-width: 506px;
  margin: 0 auto;
  @media (min-width: 800px) {
    height: 100%;
  }
`;

const ProgramCard = ({ image, title, copy }) => {
  return (
    <CardWrapper>
      <ImageContainer>
        <ImageSharp fluid={image} />
      </ImageContainer>
      <BodyContainer>
        {title}
        <p>{copy}</p>
      </BodyContainer>
    </CardWrapper>
  );
};

const Programs = () => {
  //
  const { toddler, child, adult } = useStaticQuery(QUERY);

  const progcards = useRef(null);

  const progcardsIx = useIntersection(progcards, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  return (
    <Section>
      <Container>
        <Block>
          <Header>
            <div id="header-copy">
              <p id="header-copy-quote">
                <span>
                  <Quote />
                </span>
                Swimming provides challenges and rewards accomplishments, which helps children to become self-confident
                and believe in their abilities.
              </p>
              <div id="header-copy-heading">
                <h2 id="heading">Our Programs</h2>
                <p id="header-copy-subtitle">
                  We offer swimming programs from ages 4 to adult; for people with water phobia to high-performance
                  athletes. <em>We have it all.</em>
                </p>
              </div>
            </div>
            <div id="header-feature-image">
              <FeatureImageWrapper>
                <Image1 />
              </FeatureImageWrapper>
            </div>
          </Header>
          <Grid
            variants={container}
            initial="initial"
            animate={progcardsIx && progcardsIx.isIntersecting ? 'enter' : 'initial'}
            exit="exit"
            ref={progcards}
          >
            <motion.div key="programCard1" variants={item}>
              <ProgramCard
                image={toddler.childImageSharp.fluid}
                art="4-6"
                title={
                  <h2>
                    4-6 Years Old <Toddler />
                  </h2>
                }
                copy="The transition from toddlers to childhood comes with many key milestones. Let swimming be the cornerstone of that journey."
              />
            </motion.div>
            <motion.div key="programCard2" variants={item}>
              <ProgramCard
                art="7-14"
                image={child.childImageSharp.fluid}
                title={
                  <h2>
                    7-14 Years Old <Dab />
                  </h2>
                }
                copy="Swimming keeps your child’s heart and lungs healthy, improves strength and flexibility, increases stamina and even improves balance and posture."
              />
            </motion.div>
            <motion.div key="programCard3" variants={item}>
              <ProgramCard
                art="adults"
                image={adult.childImageSharp.fluid}
                title={
                  <h2>
                    Adults <Adults />
                  </h2>
                }
                copy="Health, fun, and confidence are not the only reasons why you should learn to swim. Learning to swim also opens up the door to a range of other activities."
              />
            </motion.div>
          </Grid>
          {/* <div className="pt-20 text-center">
            <Button type="button" onClick={() => navigate('/trial')} onKeyDown={() => navigate('/trial')}>
              Book Free Trial Lesson
            </Button>
          </div> */}
        </Block>
      </Container>
    </Section>
  );
};

export default Programs;

ProgramCard.propTypes = {
  image: PropTypes.object.isRequired,
  copy: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
};

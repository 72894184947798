import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const QUERY = graphql`
  query {
    breakImage: file(sourceInstanceName: { eq: "images"}, name: { eq: "break" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`;

const BreakImage = () => {
  //
  const { breakImage } = useStaticQuery(QUERY);

  return <ImageSharp fluid={breakImage.childImageSharp.fluid } />
};

export default BreakImage;
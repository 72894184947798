import React from 'react';
import styled from 'styled-components';
import { Container } from '../primitives';
import Form from './NewsletterForm';

const Section = styled.section`
  background: #edf2f7;
  position: relative;
`;

const FormWrapper = styled.div`
  #form-wrapper {
    #grid-firstname {
      grid-area: firstname;
    }
    #grid-lastname {
      grid-area: lastname;
    }
    #grid-email {
      grid-area: email;
    }
    #grid-privay {
      grid-area: privacy;
    }
    #grid-button {
      grid-area: button;
      display: grid;
      justify-content: flex-end;
    }
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      'firstname firstname'
      'lastname lastname'
      'email email'
      'priavy button';
    @media (min-width: 480px) {
      grid-template-areas:
        'firstname lastname'
        'email email'
        'priavy button';
    }
    @media (min-width: 680px) {
      grid-template-areas:
        'firstname lastname email'
        'priavy privacy button';
    }
    @media (min-width: 800px) {
      grid-template-areas:
        'firstname lastname email'
        'priavy privacy button';
    }
  }
`;

const Block = styled.div`
  padding: 3rem 0;
`;

const Heading = styled.div`
  text-align: center;
  h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  p {
    font-size: 0.875rem;
    margin: 0 0 2.5rem 0;
  }
`;

const Newsletter = () => {
  //
  return (
    <Section>
      <Container>
        <Block>
          <Heading>
            <h2>Sign up for SDAA emails</h2>
            <p>Offers, events & cool new gear</p>
          </Heading>
          <FormWrapper>
            <Form />
          </FormWrapper>
        </Block>
      </Container>
    </Section>
  );
};

export default Newsletter;
